@import './breakpoints';
@import './variables';
@import './mixins';

.cart-remove-link {
	cursor: pointer;
    border-bottom: 1px solid;
    margin: 0 20px;
    font-family: $secondary-font;
}
// Cart Shipping
.cart-shipping-left-content{
    .contact-ship-box{
        @include border(1px, solid, $off-gray);
        padding: 0 15px;
        border-radius: 5px;
    }
    .top-para{
        color: $off-black;
        font-size: $normal-font-size;
        line-height: 18px;
        font-weight: $light-font;
    }
    .change-btn{
        background:transparent;
        border: 0;
        font-size: $body-size;
    }
    .shipping-method-title{
        font-weight: $normal-font;
        font-size: $title-h4;
        color: $light-black;
    }
    .acc-success-msg {
        font-weight: bold !important;
        color: #4eae3a;
    }
}
.return-link{
    position: relative;
    color: $black;
    text-decoration: none;
    font-family: $primary-font;
    font-size: $normal-font-size;
    font-weight: $normal-font;
    &::after{
        position: absolute;
        content: '';    
        top: 4px;
        left: 0;
        background-image: url(../images/left-arrow.png);
        width: 6px;
        height: 11px;
        background-repeat: no-repeat;
    }
}
.continue-btn {
    background: $black;
    color: $white;
    font-size: $normal-font-size;
    font-weight: $normal-font;
    padding: 15px !important;
    font-family: $primary-font;
    border-radius: 0px;
}
.chkbox-detail {
	display: flex !important;
    margin-top: -22px;
    margin-left: 18px;
    padding-left: 6px;
}
.sngl-condition {
	margin-left: 10px;
    cursor: pointer;
    color: #004cff;
    border-bottom: 1px solid;
}
.condition-modal {
    .modal-dialog {
        max-width: 900px;
    }
}
.condition-modal {
    .btn-close {
        background: none;
        opacity: 1 !important;
    }
    .btn-close::after {
        content: '\2716';
        color: #f00;
        font-size: 28px;
        line-height: 0;
    }
}
.payment-method-chk {
	margin-top: 8px;
}
label.payment_option {
	position: relative;
    cursor: pointer;
    border: 1px solid #bbb;
    border-radius: 6px;
}
label.payment_option input {
	opacity: 0;
	position: absolute;
}
label.payment_option .markpayment {
	display: inline-block;
	border-radius: 4px;
	background: #bdd7c0;
	position: relative;
}
label.payment_option .markpayment::before {
	position: absolute;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.3);
	content: "";
	top: 0;
	left: 0;
}
label.payment_option .markpayment img {
	width: auto;
	height: auto;
	max-height: 100%;
}
label.payment_option .markpayment::after {
	position: absolute;
    content: "";
    left: calc(50% - 15px);
    top: calc(50% - 15px);
    width: 15px;
    height: 30px;
    border: solid #ffe300;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}
.cart-shipping-product-tax {
    font-size: 12px !important;
    color: #00000091  !important;
}
// Cart shipping amount section
.cart-shipping-amount{
    .cart-prod-img-box {
        background: $box-white;
        border-radius: 5px;
        margin-right: 15px;
        position: relative;
        @include border(1px,solid, #ddd);

        span {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 22px;
            height: 22px;
            background: $off-black;
            line-height: 22px;
            border-radius: 50%;
            color: $white;
            font-weight: $normal-font;
        }
        img {
            height: 64px;
            padding: 3px;
        }
    }
    .cart-prod-sm-title{
        font-size: $product-name-size;
        font-weight: $title-font;
    }
    .cart-prod-color-title{
        font-size: $sm-prod-title;
        font-weight: $light-font;
        color: $light-gray;
    }
    .price{
        font-weight: 600;
        font-family: $secondary-font;
    }
    .cart-total-amnt-table{
        border:0px !important;
        tbody td{
            border:0px !important;  
            padding: 4px 0;
        }
        tfoot tr{
            border-top: 1px solid #ddd !important;
        }
        tfoot td{
            border:0px;
            padding: 4px 0;
        }
        .bold-td{
            font-weight: $title-font;
            font-family: $secondary-font;
        }
        .text-end.bold-td {
            float: right;
        }
    }
    .apply-coupon-btn{
        background-color: #939393 !important;
        border: 0;
        padding: 8px 30px;
        margin-left: 10px;
        color: #ffffff !important;
        font-size: 14px !important;
        border-radius: 5px !important;
        display: flex;
        padding-top: 10px;

        .coupon-refresh-btn {
            margin-left: 12px;
            font-size: 16px;
        }
    }
}
.estimate-date-picker {
    cursor: pointer;
}
.right-bg {
    background: #FAFAFA;
    height: 100%;
    width: 43%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: -10;
}

// Cart Information Sass
.contact-info-title{
    font-size: 18px !important;
    color: #545454 !important;
}
.login-link {
	color: $black;
	text-decoration: none;
    font-family: $primary-font;
}
.ship-cancel-info{
    font-size: 18px !important;
}
.payment-btn-size {
    font-size: 20px !important;
    padding: 8px !important;
    padding-bottom: 8px !important;
    padding-bottom: 10px !important;
}
// Cart payment Sass
.box{
    @include border(1px,solid, $off-gray);
    padding: 15px;
    border-radius: 5px;
}
.payment-card-tab .nav-link{
	color: $Very-dark-gray;
	background-color: transparent !important;
    font-size: $normal-font-size;
}
.payment-card-tab .nav-link.active{
	color: $black;
	background-color: transparent !important;
}
.inp-abs-img-div{
    position: relative;
    .inp-abs-img{
        position: absolute;
        right: 25px;
        top: 10px;
    }
}

.mobile-category-price-show {
	position: absolute;
	right: 24px;
	top: 170px;
	font-size: 18px;
    z-index: 9;
}
.add-address-btn.change-add-address-btn {
	margin: auto 135px;
}
.change-shipping-modal {
    .modal-dialog {
        max-width: 550px !important;
    }
}
// ============== Responsive ================

@include breakpoints(LGphoneOnly) {

    .contact-info-title {
        font-size: 14px !important;
    }
    .cart-shipping-left-content .acc-success-msg {
        font-size: 14px;
    }
    .add-address-btn.change-add-address-btn {
        margin: auto 50px;
    }
    .sngl-condition {
        margin-left: 3px;
    }
}