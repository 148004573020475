
@import './breakpoints';
@import './variables';
@import './mixins';

// img {
// 	width: 100%;
// }
a {
    text-decoration: none !important;
    color: $black;
}
.image-modal-clg.modal-header {
	display: block;
	text-align: center;
	margin-top: -15px;
	padding-bottom: 25px;
}
.image-modal-clg.modal-header button {
	border: 1px solid;
	border-radius: 50% !important;
}
.modal-single-img-wrap .modal-content {
	width: 650px !important;
    .modal-body {
        text-align: center;
    }
}

#validation-select-box {
	border: 1px solid #f00 !important;
}
.product-details-main {
	margin: 50px 0;
    @include breakpoints(phone) {
        .product-details-main {
            margin: 0;
        }
    };
    .single-product-img-modal {
        height: 455px;
        overflow: hidden;
        background: #e1e1e1;
        text-align: center;
        cursor: pointer;

        img {
            height: 100%;
            width: auto;
        }
    }

    .callops-detail {
        @include border-direction( "top",1px, solid, #ddd);

        .size-chart-title {
            display: flex;
            justify-content: space-between;
            @include border-direction( "bottom",1px, solid, #ddd);
            padding-right: 15px;

            span {
                font-size: $buyer-profile-title-size !important;
                margin: 0;
                padding: 15px 0px;
                color: $Very-dark-gray;
            }
            p {
                font-size: $buyer-profile-title-size !important;
                margin: 0;
                padding: 15px 0px;
                color: $Very-dark-gray;
            }
        }
    }
    .accordion-collapse {
        border: 0;
    }
    .accordion-button {
        padding: 0px;
        font-weight: 600;
        border: 0;
        font-size: 17px !important;
        color: $black;
        text-align: left;
        @include border-direction( "bottom",1px, solid, #ddd);
        padding: 15px 0;
    }
    .accordion-button:focus {
        box-shadow: none;
        border: none;
    }
    .accordion-button:not(.collapsed) {
        background: none !important;
        color: #dc3545;
    }
    .accordion-body {
        padding: 15px 0;
        background-color: #fff;
        width: 85%;
        text-align: justify;
    }
    .accordion-button::after {
        width: auto;
        height: auto;
        content: "+";
        font-size: 30px;
        background-image: none;
        font-weight: 100;
        color: $Very-dark-gray;
    }
    .accordion-button:not(.collapsed)::after {
        width: auto;
        height: auto;
        background-image: none;
        content: "-";
        font-size: 48px;
    }
    .single-prdct-dtl-img {
        width: 100%;
        height: 730px;
        left: 68px;
        top: 143px;
        background: #ECEEF2;

        img {
            width: 100%;
            margin-top: 30px;
        }
    }
    .description-title {
        text-transform: uppercase;
    }

    .sp-title h1 {
        margin-bottom: 30px;
        font-size: $product-title-size;
        font-weight: 700;
        height: 55px;
        overflow: hidden;
        
    }

    .sp-price  {
        @include border-direction( "bottom",1px, solid, #ddd);
        padding-bottom: 16px;
        margin-bottom: 70px;
        font-size: 17px !important;
        font-family: $secondary-font;
        
        p{
            font-size: 19px !important;
            font-weight: 400 !important;
            font-family: $secondary-font;
        }
    }
    
    .single-prdct-dtl-content {
        margin-left: 20px;
    
        .sp-color {
            display: flex;
            @include border( 1px, solid, #ddd);
            font-size: 18px;
            float: right;
            align-items: center;
            padding: 0;
            width: 100%;
            height: 41px;
            .sp-color-title {
                margin-left: 15px;

                span {
                    color: $Very-dark-gray;
                    font-size: $body-size;
                }
            }
        
            .sp-color-name {
                border: none;
                width: 100%;
                margin-left: 5px;
                color: $Very-dark-gray;
                min-height: 0px !important;
                font-size: $body-size;
                margin-top: 3px;
            }
        }

        .sp-size {
            display: flex;
            @include border( 1px, solid, #ddd);
            font-size: 18px;
            float: right;
            align-items: center;
            padding: 0;
            width: 100%;
            height: 41px;
            margin-top: 18px;
            margin-bottom: 18px;

            .sp-size-title {
                margin-left: 15px;

                span {
                    color: $Very-dark-gray;
                    font-size: $body-size;
                }
            }
        
            .sp-size-name {
                border: none;
                width: 100%;
                margin-left: 5px;
                color: $Very-dark-gray;
                min-height: 0px !important;
                font-size: $body-size;
                margin-top: 4px;
            }
        }
        .quantity-left-minus.btn.btn-number {
            background: #fff;
            color: $Very-dark-gray;
            border-color: #ddd;
            outline: none;
            box-shadow: none;
            border-radius: 0px;
            font-size: 14px;
            border-right: none;
            border-radius: 0px !important;
        }

        .quantity-right-plus.btn.btn-number {
            background: #fff;
            color: $Very-dark-gray;
            border-color: #ddd;
            outline: none;
            box-shadow: none;
            border-radius: 0px;
            font-size: 14px;
            border-left: none;
            border-radius: 0px !important;
        }
        #quantity {
            height: 41px !important;
            text-align: center;
            border-radius: 0px !important;
        }
        .prdct-save.text-right {
            margin-top: 10px;
        }
        .prdct-save-btn {
            margin-left: 14px;
            padding: 10px 82px;
            background: #fff;
            @include border( 1px, solid, #ddd);
            color: red !important;
            text-decoration: none;
            text-transform: uppercase;

            span {
                margin-left: 4px;
                color: $Very-dark-gray;
            }
        }

        .cart-btn {
            margin-top: 35px;
            text-align: center;

            .add-cart-btn {
                width: 100%;
                padding: 11px;
                background: #000;
                color: #fff;
                @include border( 1px, solid, #ddd);
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }

        .more-info-main {
            margin-top: 59px;
            @include border-direction( "top",1px, solid, #ddd);
            @include border-direction( "bottom",1px, solid, #ddd);

            .more-info {
                text-decoration: navajowhite;
                color: $black;

                p {
                    margin: 0;
                    font-size: 13px !important;
                    font-weight: 600;
                    padding: 15px;

                    span {
                        float: right;
                        color: $Very-dark-gray;
                        font-size: 22px;
                    }
                }
            }
        }

        .custom-select-box {
            width: 100%;
            user-select: none;
            position: relative;
            margin-bottom: 20px;
        
            .custom-select-box-btn {
                background: #fff;
                color: #333;
                display: flex;
                align-items: center;
                cursor: pointer;
                justify-content: space-between;
                height: 44px;
                border: 1px solid #ddd;
                padding: 0 15px;

                .custom-select-box-icon {
                    color: #757575 !important;
                }
            }
            .custom-select-box-content {
                // position: absolute;
                // top: -77px;
                // left: -200px;
                // padding: 0;
                // background-image: url(../images/0009.png);
                // font-weight: 500;
                // color: #333;
                // width: 44%;
                // text-align: center;
                // height: 200px;
                // overflow-x: hidden;
                // scrollbar-width: none;

                position: absolute;
                top: 40%;
                left: -50%;
                padding:10px 0;
                font-weight: 500;
                color: #333;
                width: 44%;
                border: 1px solid #bbb;
                text-align: center;
                border-radius: 3px;
                background: #fff;
                transform: translateY(-50%);
        
                .custom-select-box-item {
                    padding: 5px 10px;
                    cursor: pointer;
                    transition: all 0.2s;
                    // margin-left: 3px;
                    // margin-right: 10px;
                }
                .custom-select-box-item:hover {
                    background: #f4f4f494;
                }
            }
        }
    }
    

    .similar-product-wrap {
        margin-top: 50px;
        @include border-direction( "top",1px, solid, #ddd);
        padding-top: 60px;
        overflow: clip;
        
        .smlr-prdct-title {
            text-align: center;

            h2 {
                font-size: $similar-product-title-size;
                color: $black;
                text-transform: uppercase;
                font-family: $secondary-font;
                margin-bottom: 1rem;
            }
        }
        .single-smlr-product {

            .smlr-prdct-name {
                margin-top: 20px;
                text-align: center;
    
                p {
                    font-size: $product-name-size;
                    font-weight: $product-name-font;
                    text-transform: uppercase;
                    margin-bottom: 6px;
                }
            }
        }
        
        .smlr-prdct-price {
            text-align: center;
            p {
                font-size: $product-name-size;
                font-weight: $normal-font;
            }
        }
    }

    .option-select-input-box {
        width: 100%;
        position: relative;

        .input-box-main {
            border: none !important;
            margin-left: 10px;
            font-weight: 300;
            font-size: 17px;
            color: #5C5C5C;
        }
        .input-box-main:focus-visible {
            border: none !important;
            outline: none !important;
        }
        .option-box {
            position: absolute;
            right: 125%;
            background-image: url(../images/final1.png);
            padding: 10px 65px;
            bottom: -328%;
            height: 203px;
            overflow: hidden auto;
            width: 190px;   
            ul {
                padding: 0 !important;
                margin: 0 !important;
            }
        }
        .input-select-arrow {
            position: absolute;
            right: -15px;
            top: 75px;
            font-size: 20px;
            background: #fff;
            color: #bbb;
        }
        .modal-dialog {
            margin-top: 80px;
        }
    }

   
}

.single-product-wrap {
    width: 95% !important;

    .single-product-img-wrap {
        
        text-align: center;
        background: #ECEEF2 !important;
        cursor: pointer;
        position: relative;

        img {
            width: auto;
            height: 240px;
            display: unset;
        }
    }
}
.slider-img {
    .slick-prev, .slick-next {
        top: 50% !important;
    }
}
.similar-product {
    .slick-prev, .slick-next {
        top: 35% !important;
    }
    .slick-next {
        left: 94% !important;
    }
}
.rcnt-view-product {
    .slick-prev, .slick-next {
        top: 35% !important;
    }
    .slick-next {
        left: 94% !important;
    }
}
.new-arr-slider {
    .slick-prev, .slick-next {
        top: 35% !important;
    }
    .slick-next {
        left: 94% !important;
    }
}
.upc-product-slider {
    .slick-prev, .slick-next {
        top: 35% !important;
    }
    .slick-next {
        left: 94% !important;
    }
}
.rcnt-view-product-wrap {
    margin-top: 50px;
    @include border-direction( "top",1px, solid, #ddd);
    padding-top: 60px;
    overflow: clip;
    
    .rcnt-view-prdct-title {
        text-align: center;

        h2 {
            font-size: $similar-product-title-size;
            color: $black;
            text-transform: uppercase;
            font-family: $secondary-font;
            margin-bottom: 1rem;
        }
    }
    .rcnt-view-prdct-name {
        margin-top: 20px;
        text-align: center;

        p {
            font-size: $product-name-size;
            font-weight: $product-name-font;
            text-transform: uppercase;
            margin-bottom: 6px;
        }
    }
    .rcnt-view-prdct-price {
        text-align: center;
        p {
            font-size: $product-name-size;
            font-weight: $normal-font;
        }
    }
    
}

// ************* color & size *******************

.custom-select-box-content:before, .custom-select-box-content:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 100%;
    margin-right: 2px;
    border: 12px outset transparent;
}

.custom-select-box-content:before {
        border-left: 15px solid #c4c7d7;
        top: 45%;
}

.custom-select-box-content:after {
    border-left: 15px solid #c4c7d7;
    top: 45%;
}



// ============== Responsive ================

@include breakpoints(LGphoneOnly) {

    .product-details-main {
        margin: 0;
        margin-bottom: 30px;
        .px0 {
            padding-left: 0;
            padding-right: 0;

            .single-prdct-dtl-img {
                height: 375px;
    
                img {
                    margin-top: 10px;
                }
            }
            .product-add-icon {
                position: absolute;
                top: 100px;
                right: 25px;
                background: #fff;
                height: 35px;
                width: 35px;
                text-align: center;
                line-height: 35px;
                border-radius: 50%;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    
                span {
                    font-size: 25px;
                    color: #8F8F8F;
                }
            }
        }
        .single-prdct-dtl-content {
            margin-top: 35px;
            margin-left: 0 !important;
            text-align: center;

            .sp-title h5 {
                line-height: 34px;
                margin-bottom: 0;
            }
            .sp-price {
                margin-top: 18px;

                p {
                    padding-bottom: 5px !important;
                    margin-left: 18px;
                    margin-right: 25px;
                    margin-bottom: 0;
                }
            }
            .prdct-save-btn {
                margin-left: 12px;
                padding: 10px 60px;
            }
            .cart-btn {
                margin-top: 25px !important;
            }
        }
        .accordion-button::after {
            margin-right: 15px;
        }
        .similar-product-wrap {
            padding-top: 40px;
        }
       
        .accordion-body {
            width: 100%;
        }

       .slick-next {
            left: 89% !important;
        }
    }
    .modal-single-img-wrap .modal-content {
        width: 100% !important;
    }
    .rcnt-view-product-wrap {
        padding-top: 40px;
        margin-top: 30px;
    }
    .single-product-wrap {
        width: 97% !important;
        margin-left: 5px !important;

        .single-product-img-wrap {
            height: 100% !important;

            img {
                padding: 15px 0 !important;
                width: auto !important;
            }
        }
    }

    .custom-select-box .custom-select-box-content {
        bottom: 60px !important;
        top: auto !important;
        left: 0 !important;
        width: 100% !important;
        background: #e0e0e0 !important;
        height: unset !important;
        transform: none !important;
    }
    .custom-select-box-content:before, .custom-select-box-content:after {
        display: block;
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        top: 100%;
        margin-right: 2px;
        border: 12px outset transparent;
    }
    .custom-select-box-content:before {
        border-top: 15px solid #c4c7d7;
        left: 45% !important;
    }   
    .custom-select-box-content:after {
        border-top: 15px solid #c4c7d7;
        left: 45% !important;
    }
}



 

