
.slick-prev {
  left: 2% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}
.slick-prev::before, .slick-next::before {
	font-size: 32px!important;
}
#side-categories-list .accordion-button {
	padding-right: 25px !important;
	padding-left: 10px !important;
}
#side-category-mobile-view .accordion-button {
	background: #FAFAFA;
	border-top: 1px solid #ddd;
	border-bottom: 1px solid #ddd;
	padding: 16px 6px !important;
	position: relative;
}
#side-category-mobile-view .accordion-button::after {
	margin-left: 5px !important;
	padding-left: 10px !important;
}
#side-categories-list .accordion-button span {
	padding-right: 20px;
	padding-left: 10px;
	text-transform: uppercase;
}
#side-categories-list .accordion-button span a {
	text-decoration: none;
	color: $light-black;
}
.accordion-body span {
    cursor: pointer;
}
.show-all-link {
	font-size: 18px;
	text-decoration: none;
	color: #000;
	font-weight: 500;
	border: 1px solid #000;
	padding: 5px 10px;
}
.load-more-link {
	background: #000 !important;
	text-decoration: none !important;
	color: #fff !important;
	font-weight: 500 !important;
	border: 1px solid #000 !important;
	padding: 8px 45px !important;
	font-size: 16px !important;
}
.slider-img img {
	height: 300px;
	width: 100%;
}
.alrt-msg-show {
	margin: 0 auto !important;
	width: 97% !important;
}
.alrt-name {
	margin-bottom: 0 !important;
}
//Initial Home Page
.header-border{
	@include border-direction(bottom, 2px, solid,#C4C4C4);
}
.search-bg {
	background-color: #000;
}
.home-content{
	height: 100vh;
	@include border-direction(bottom, 1px, solid, #C4C4C4);

	.country-select-box {
		width: 430px;
		height: 44px;
		user-select: none;
		margin: 150px auto;
		margin-top: 200px;
		position: relative;
		@include breakpoints(LGphoneOnly) {
			width: 300px !important;
		};

		.country-select-box-btn {
			background: #fff;
			box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
			font-weight: bold;
			color: #000;
			display: flex;
			align-items: center;
			cursor: pointer;
			justify-content: space-between;
			height: 44px;
			padding-left: 15px;
			padding-right: 3px;
			letter-spacing: 1px;
			font-weight: 400;
			text-transform: uppercase;

			.country-select-box-icon {
				background: #000;
				color: #fff;
				height: 38px;
				width: 38px;
				text-align: center;
				line-height: 39px;
				font-size: 26px;
			}
		}
		.country-select-box-content {
			position: absolute;
			top: 103%;
			left: 0;
			padding: 10px;
			background: #fff;
			box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
			font-weight: 500;
			color: #333;
			width: 100%;
			height: 180px;
			overflow: auto;

			.country-select-box-item {
				padding: 10px;
				cursor: pointer;
				transition: all 0.2s;
				text-transform: uppercase;
			}
			.country-select-box-item:hover {
				background: #f4f4f4;
			}
		}
	}

}


// ************ search bar **************

input {
    font-family: 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
    color: #555860;
}

.search-product {
    position: relative;
	margin: 0 auto;
	width: 100% !important;
	top: 2px;
}
.header-search-box-main.dropdown-menu[data-bs-popper] {
	background: none !important;
	border: none !important;
}
.header-search-box-main .custom-search-btn {
		position: absolute;
		left: 0px;
		top: 0;
		height: 44px;
		width: 60px;
		background: #fff;
		border: 1px solid #bbb;
		text-align: center;
		padding-top: 9px;
		padding-left: 40px;
		border-right: none;
		z-index: 9;
		cursor: pointer;
	}
.header-search-box-main .form-control {
	border-right: none !important;
	padding-left: 80px !important;
	border-left: none !important;
}
.header-search-box-main .form-control:focus {
	border-color: #bbb;
}
.search-product input {
    height: 26px;
    width: 100%;
    padding: 0 12px 0 25px;
    border-width: 1px;
    border-style: solid;
    border-color: #a8acbc #babdcc #c0c3d2;
    border-radius: 13px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
    -moz-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
    -ms-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
    -o-box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
    box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
}

.search-product input:focus {
    outline: none;
    border-color: #66b1ee;
    -webkit-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    -moz-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    -ms-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    -o-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
}

.search-product input:focus + .results { display: block }

.search-product .results {
    display: none;
    position: absolute;
	width: 100%;
    top: 50px;
    left: 0;
    right: 35px;
    z-index: 10;
    padding: 0;
    margin: 0;
    border-width: 1px;
    border-style: solid;
	border: none !important;
    border-color: #cbcfe2 #c8cee7 #c4c7d7;
    border-radius: 3px;
    background-color: #fdfdfd;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdfdfd), color-stop(100%, #eceef4));
    background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -ms-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: linear-gradient(top, #fdfdfd, #eceef4);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.search-product .results { display: block }

.search-product .results li:first-child { margin-top: -1px }

.search-product .results li:first-child:before, .search .results li:first-child:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    margin-left: -5px;
    border: 5px outset transparent;
}

.search-product .results li:first-child:before {
    border-bottom: 5px solid #c4c7d7;
    top: -11px;
}

.search-product .results li:first-child:after {
    border-bottom: 5px solid #fdfdfd;
    top: -10px;
}

.search-product .results li:first-child:hover:before, .search .results li:first-child:hover:after { display: block }

.search-product .results li:last-child { margin-bottom: -1px }

.search-product .results a {
    display: block;
    position: relative;
    margin: 0 -1px;
    padding: 6px 40px 6px 10px;
    color: #808394;
    font-weight: 500;
    text-shadow: 0 1px #fff;
    border: 1px solid transparent;
    border-radius: 3px;
}

.search-product .results a span { font-weight: 200 }

.search-product .results a:before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -9px;
    background: url("https://cssdeck.com/uploads/media/items/7/7BNkBjd.png") 0 0 no-repeat;
}

.search-product .results a:hover {
    text-decoration: none;
    color: #fff;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
    border-color: #eceef2  #eceef2  #eceef2 ;
    background-color: #eceef2 ;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #59aaf4), color-stop(100%, #338cdf));
    background-image: -webkit-linear-gradient(top, #59aaf4, #338cdf);
    background-image: -moz-linear-gradient(top, #59aaf4, #338cdf);
    background-image: -ms-linear-gradient(top, #59aaf4, #338cdf);
    background-image: -o-linear-gradient(top, #59aaf4, #338cdf);
    background-image: linear-gradient(top, #59aaf4, #338cdf);
    -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    -ms-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    -o-box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0 1px rgba(255, 255, 255, 0.2), 0 1px rgba(0, 0, 0, 0.08);
}

:-moz-placeholder {
    color: #a7aabc;
    font-weight: 200;
}

::-webkit-input-placeholder {
    color: #a7aabc;
    font-weight: 200;
}

.lt-ie9 .search-product input { line-height: 26px }




// **************** Responsive *****************

@include breakpoints(LGphoneOnly) {

	.slider-img img {
		height: 220px;
	}
	.category-mobile-view {
		margin-top: 25px;
	}
	.mobile-view-cat-main {
		border: 1px solid #ccc !important;
		margin-bottom: 20px !important;
		margin-top: -20px !important;
	}
	.mobile-view-cat-title {
		margin-bottom: 0 !important;
	}
	.search-product {
		width: 100% !important;
		left: 1px !important;
		z-index: 9;
	}
	.header-search-box-main .custom-search-btn {
		padding-left: 6px;
	}
	.header-search-box-main .form-control {
		// padding-left: 20px !important;
	}
}

.sub-menu-name {
	.active{
		color: #0c63e4 !important;
	}
}

#address-active{
	border: 1px red solid;
}








