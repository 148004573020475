@mixin breakpoints($points) {
  @if $points == "phoneOnly" {
    @media (max-width:319px) { @content ; }
 }
  @if $points == "phone" {
    @media (min-width: 320px) and (max-width:414px) { @content ; }
 }
  @if $points == "LGphoneOnly" {
    @media (max-width:575px) { @content ; }
 }
 @if $points == "LGphone" {
  @media (min-width: 415px) and (max-width:767px) { @content ; }
}
  @else if $points == "tablet" {
    @media (min-width: 768px) and (max-width:800px) { @content ; }
 }
 @else if $points == "LGtablet" {
  @media (min-width: 801px) and (max-width:991px) { @content ; }
}
   @else if $points == "desktop" {
    @media (min-width: 992px) and (max-width:1366px){ @content ; }
 }
  @else if $points == "LGdesktop" {
    @media (min-width: 1367px)  { @content ; }
 }
 @else if $points == "XXLdesktop" {
    @media (min-width: 1920px)  { @content ; }
 }
}

// Usage
// @include breakpoints(phoneOnly) {
//   background-color: rgb(57, 230, 4) !important;
// };