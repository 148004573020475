

.message-box{
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}
.chat-list-box {
    .clb-title {
        padding: 20px;
    }
    .list-item {
        padding: 15px 30px;
        border-bottom: 1px solid #E7E7E7;
        display: block;
        color: #000;
        text-decoration: none;
    }
    .clb-pname {
        color: #737373;
        font-size: 14px;
        line-height: 19px;
        width: 55%;
    }
    .inv-no h6 {
        font-weight: 300;
    }
}
.main-chat-body{
    .chat-header {
        padding: 10px;
        border-bottom: 1px solid #edededed;
        .circle-icon{
            color: #373E66;
        }
        .bpi-box {
            width: 45px;
            margin-right: 10px;
        }
        .icon-box {
            padding: 10px 15px;
            font-size: 18px;
            color: #373E66;;
        }
        .icon-box a{
            color: #373E66;;
        }
        .main-chat-body {
            border-bottom: 1px solid #E7E7E7;
        }
    }
    .chat-body {
        padding: 15px;
        overflow: hidden;
        .self-message {
            margin-bottom: 10px;
            overflow: hidden;
            .sm-message {
                margin-left: 10px;
                background: #ededed;
                padding: 10px 15px;
                width: fit-content;
                border-radius: 0px 5px 5px 5px;
                p{
                    margin-bottom: 0px;
                }
            }
        }
        .feedback-message {
            margin-bottom: 10px;
            overflow: hidden;
            .fm-message {
                margin-right: 10px;
                background: #ededed;
                padding: 10px 15px;
                width: fit-content;
                border-radius: 5px 0px 5px 5px;
                p{
                    margin-bottom: 0px;
                }
            }
        }
    }

    
}