@import './breakpoints';
@import './variables';
@import './mixins';

.buyer-profile-wrap {
	margin: 50px 0;

    .logout-btn {
        margin: 20px 0px;

        a {
            font-size: $buyer-profile-title-size;
            text-decoration: none;
            text-transform: uppercase;
            color: $black;

            span {
                margin-left: -18px;
                color: #555;
                padding-right: 3px;
            }
        }
    }
    .account-name {
        margin-bottom: 40px;

        h1 {
            font-size: $title-size;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 15px;
        }
        p {
            font-size: $body-size;
            color: $Very-dark-gray;
        }
    }
    .buyer-profile-main {

        .buyer-profile-category {
            @include border-direction( "bottom",1px, solid, #ddd);
            ul {
                display: flex;
                justify-content: space-between;
                border-bottom: none !important;

                button {
                    text-decoration: none;
                    color: $black;
                    text-transform: uppercase;
                    font-size: $buyer-profile-title-size;
                    font-weight: $light-font;
                    border: none !important;
                    margin-bottom: 0;
                    padding: 8px 0;
                }
                .category-item.active {
                    background: #fff !important;
                    font-weight: 700 !important;
                }
            }
        }
        .buyer-profile-details {

            .buyer-profile-img {
                margin: 30px 0;
                text-align: center;
                margin-bottom: 0;
                position: relative;

                img {
                    height: 142px;
                    width: 142px;
                    border-radius: 50%;
                }
            }
            .change-pass {
                position: absolute;
                margin-top: -30px;
                left: 464px;
                font-size: 12px;
                text-decoration: none;
                color: $black;
                font-weight: $normal-font;
            }

            .edit-profile-btn {
                margin-top: 35px;
                text-align: center;

                .buyer-profile-edit {
                    font-size: $buyer-profile-title-size;
                    text-decoration: none;
                    color: $white;
                    background: $black;
                    text-transform: uppercase;
                    padding: 10px 25px;
                    @include border( 1px, solid, #ddd);
                    letter-spacing: 1px;
                }
            }
            .img-upld-btn {
                position: absolute;
                right: 42%;
                bottom: 15px;
                background: #fff;
                height: 35px;
                width: 35px;
                line-height: 35px;
                border-radius: 50%;
                color: #444;
                font-size: 18px;
                box-shadow: 0px 0 5px 2px #666;
                cursor: pointer;
                z-index: 99;
            }
            .edit-profile-img-upld-btn {
                position: absolute;
                right: 42%;
                bottom: 45px;
                background: #fff;
                height: 35px;
                width: 35px;
                line-height: 35px;
                border-radius: 50%;
                color: #444;
                font-size: 18px;
                box-shadow: 0px 0 5px 2px #666;
                cursor: pointer;
                z-index: 99;
            }
            .image-item {
                position: absolute;
                top: 0px;
                left: 49.8%;
                transform: translateX(-50%);
            }
        }
        .other-info-title {
            @include border-direction( "bottom",1px, solid, #ddd);
            margin: 0 13px;
            margin-bottom: 0;
            padding: 0;
            h5 {
                font-size: $buyer-profile-title-size;
                font-weight: $title-font;
                text-transform: uppercase;
                margin-bottom: 10px;
                letter-spacing: 1px;
                color: $gray;
            }
        }
        .buyer-other-info {
            margin-left: 40px;
            margin-top: -27px;
            position: relative;
            cursor: pointer;

            .other-info-detail {
                padding: 10px;

                p {
                    font-size: $body-size;
                    font-weight: $light-font;
                    color: $Very-dark-gray;
                }
            }
            .add-other-info {
                margin-top: 62px;
                background: #f2f2f2;
                padding: 20px;
                border-radius: 3px;
                padding-top: 30px;
                height: 175px;
                width: 100%;
            }
            .edit-address-btn {
                margin-top: 35px;

                .buyer-address-edit {
                    font-size: $buyer-profile-title-size;
                    text-decoration: none;
                    color: $white;
                    background: $black;
                    text-transform: uppercase;
                    padding: 9px 12px;
                    @include border( 1px, solid, #ddd);
                    letter-spacing: 1px;
                }
            }
        }
        .ml0 {
            margin-left: 0 !important;
        }

        // my order

        .order-details {
            margin-top: -45px;

            .order-list {
                @include border-direction( "bottom",1px, solid, #ddd);
                line-height: 50px;

                td {
                    text-align: center;
                }
                a{
                    text-decoration: none;
                    color: #000;
                }
            }
        }
        .bill-to-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .bill-print-btn {
                border: 1px solid #bbb;
                padding: 8px 30px;
                color: #444;
                cursor: pointer;
            }
        }

        // Mobile Buyer profile category

        .mobile-buyer-profile-category {
            
            ul {
                display: inline-block;
                li {
                    @include border-direction( "bottom",1px, solid, #fff);
                    width: 255px;

                    button {
                        text-decoration: none;
                        color: $black;
                        text-transform: uppercase;
                        font-size: $buyer-profile-title-size;
                        font-weight: $light-font;
                        border: none !important;
                        margin-bottom: 0;
                        padding: 12px 0;
                    }
                    span {
                        margin-top: 7px;
                    }
                }
                .category-item.active {
                    background: none !important;
                    font-weight: 700 !important;
                }
            }
        }
    }

    .profile-detail {
        border-bottom: 1px solid #ddd;
        padding: 10px 0;
    }
    .gender-box {
        display: flex;
        padding: 8px 0;
        label {
            margin-left: 20px;
        }
    }
}

.change-add-other-info {
    margin: 25px 0px;
    background: #f2f2f2;
    padding: 10px;
    border-radius: 3px;
    padding-top: 15px;
    height: 175px;
    width: 100%;
}
.btn-outline-dark:hover {
	color: #fff !important;
}
.change-buyer-other-info {
	margin: 0px;
	margin-top: -27px;
	position: relative;
	cursor: pointer;
}
.add-new-change-btn {
	width: 100%;
	border: 1px solid #ddd;
	background: #000;
	color: #fff;
	height: 44px;
}
.address-select {
    border: 1px solid #f00;
}
//====================Edit Profile page ==================

.box-width {
	width: 100%;
    height: 41px;
    margin-top: 20px;
    @include border( 1px, solid, #ddd);
    color: #5C5C5C !important;
    padding-left: 8px;
}
.selct-box-border {
	@include border( 1px, solid, $off-white);
    color: #333;
    padding: 0 6px;
    background: none;
    
}
.edit-profile-update {
    font-size: $normal-font-size;
    text-decoration: none;
    color: #ffffff !important;
    background: #000000 !important;
    text-transform: uppercase;
    padding: 10px 40px;
    @include border( 1px, solid, #ddd);
    letter-spacing: 1px;
}
.edit-profile-update:hover {
	color: $black;
	background: $white;
}
.edit-profile-discard {
    font-size: 14px !important;
    text-decoration: none;
    color: $black;
    background: $white;
    text-transform: uppercase;
    padding: 10px 40px;
    @include border( 1px, solid, #ddd);
    letter-spacing: 1px;
    margin-right: 16px;
}
.edit-profile-discard:hover {
	color: $white;
	background: $black;
}
.gender-input-box {
	@include border( 1px, solid, #ddd);
	margin-top: 21px;
	height: 41px;
	padding: 10px;
    padding-left: 0;
    color: #5C5C5C;

    .gender-input {
        margin-left: 10px;
    }
}
.change-profile-text {
	text-transform: uppercase;
	@include border-direction( "bottom",1px, solid, #ddd);
	margin: 10px 45%;
	font-size: $product-name-size;
	color: #333;
}

//============= Edit profile date picker ==============
.dob-date-picker {
    .dob-box-width {
        width: 100%;
        height: 41px;
        margin-top: 20px;
        @include border( 1px, solid, #ddd);
        color: #5C5C5C !important;
        padding-left: 8px;
    }
    .box-width {
       border: none;
    }
    button {
        @include border( 1px, solid, #bbb);
    }
    select {
        border: 1px solid #bbb;
        min-height: 0 !important;
    }
} 

// ============= Add New Address =============
.address-name {
    margin-bottom: 25px;

    h5 {
        font-size: $title-size;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 15px;
    }
}

.add-address-btn {
    margin-bottom: 50px;

    .buyer-address-add {
        font-size: $buyer-profile-title-size;
        text-decoration: none;
        color: $white;
        background: $black;
        text-transform: uppercase;
        padding: 10px 12px;
        padding-bottom: 10px;
        @include border( 1px, solid, #ddd);
        letter-spacing: 1px;
        width: 195px;
        text-align: center;
        cursor: pointer;
    }
}

.edit-delete-address-btn {
	margin-top: 20px;

    .edit-dflt-address, .delete-dflt-address  {
        color: #535353 !important;
        margin-right: 15px;
        text-decoration: none;
        border-bottom: 1px solid #bbb !important;
        padding: 0 5px;
        cursor: pointer;
        background: none;
        background-color: rgba(0, 0, 0, 0);
        border: none;
        border-top-color: currentcolor;
        border-right-color: currentcolor;
        border-bottom-color: currentcolor;
        border-bottom-style: none;
        border-bottom-width: medium;
        border-left-color: currentcolor;
        font-size: 15px !important;
        border-radius: unset !important;

    }
    .delete-dflt-address:hover {
        background-color: transparent !important;
        border-color: #bbb !important;
    }
    .delete-dflt-address:focus  {
        background-color: transparent !important;
        border-color: #bbb !important;
    }
    
}

.address-edit-box {
    
    .MuiInputLabel-formControl {
        top: 12px;
        left: 10px;
        position: absolute;
        transform: translate(4px, 14px) scale(1);
        background: #fff;
        padding: 0 5px;
        margin-left: 14px;
        margin-top: 0;
    }
    .MuiInputLabel-outlined {
        transform: translate(6px, 13px) scale(1);
    }
    .MuiInputLabel-shrink {
        transform: translate(4px, -6px) scale(0.75) !important;
    }
    .MuiOutlinedInput-input {
        padding: 10px 14px !important;
    }
    .cuntry-select {
        margin-left: 23px;
        border-color: #c4c4c4 !important;
        border-radius: 3px !important;
    }
    .edit-an-address-btn {
        width: 93.5%;
        border: 1px solid #ddd;
        background: #000;
        color: #fff;
        height: 44px;
    }
}


// WishList

.single-wishlist-product {

    .wishlist-prdct-img {
        text-align: center;
        background: #ECEEF2 !important;
        position: relative;

        img {
            width: auto;
            height: 240px;
            display: unset;
        }
        .product-wishlist-remove {
            position: absolute;
            top: 5px;
            right: 8px;
            cursor: pointer;
        }
    }
    .wishlist-prdct-name {
        margin-top: 20px;
        text-align: center;

        p {
            font-size: $product-name-size;
            font-weight: $product-name-font;
            text-transform: uppercase;
            margin-bottom: 6px;
        }
    }
}

.wishlist-prdct-price {
    text-align: center;
    p {
        font-size: $product-name-size;
        font-weight: $normal-font;
    }
}
.promo-code-title {
	background: #ddd;
}
.promo-code-title p {
	margin: 0px !important;
    padding: 8px;
    font-weight: 400 !important;
    font-size: 16px !important;
    text-align: center !important;
}
.prmo-title-name {
    padding-right: 40px !important;
}
.non-promo-msg {
    padding: 12px;
    color: #f00;
}
.promo-code-body table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
  }
  
  .promo-code-body td, th {
    text-align: center;
    padding: 8px 4px;
  }
  
  .promo-code-body tr:nth-child(even) {
    background-color: #eee;
  }
 .time-counter-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px;
    width: 100%;

    section{
        text-align: center;
    }
    p {
        font-size: 20px !important;
        font-weight: 400 !important;
        margin-bottom: 0 !important;
    }
    .time-title {
        font-size: 14px !important;
        font-weight: 300 !important;
    }
    span {
        margin-top: -30px;
        font-size: 20px;
        font-weight: 700;
    }
}
.time-counter-title {
    p {
        margin: 0;
        font-weight: 500 !important;
    }
}
// ================ Responsive =====================

@include breakpoints(LGphoneOnly) {
    .buyer-profile-wrap {
        margin: 20px 0;
        margin-bottom: 50px;
        .account-name {
            margin-bottom: 20px;
        }
        .personal-info-title {
            @include border-direction( "bottom",1px, solid, #bbb);
                margin-bottom: 50px;
            p {
                font-size: $normal-font-size;
                text-transform: uppercase;
                font-weight: $product-name-font;
                margin-bottom: 10px;
            }
        }
        .buyer-profile-main {

            .buyer-profile-details {

                .edit-profile-btn {
                    text-align: left !important;
                }
                .change-pass {
                    margin-top: -32px !important;
                    left: 75% !important;
                    font-weight: 500 !important;
                }
                .buyer-profile-img {
                    margin: 0px !important;

                    .change-profile-text {
                        margin: 10px 35%;
                    }
                }
                .update-profile-btn {
                    text-align: center;
                }
                .profile-detail {

                    span {
                        font-size: 13px;
                    }
                }
                .img-upld-btn {
                    right: 29%;
                }
                .edit-profile-img-upld-btn {
                    right: 29%;
                }
                
            }
            
            .buyer-other-info {
                margin-top: 25px;
                margin-left: 0 !important;
                .add-other-info {
                    margin-top: 0;
                }
            }
            .other-info-title {
                margin-top: 40px;
                margin-left: 0px !important;
            }
        }
        .logout-btn a span {
            margin-left: 0 !important;
        }
        .add-address-btn {
            margin-bottom: 0 !important;
        }
    }

    .cuntry-select {
        width: 90%;
        margin-bottom: 20px;
    }
    
    .wishlist-prdct-img {
        img{
            height: 180px !important;
        }
    }

}
.select-distict {
	border: 1px solid #bbb;
	border-radius: 5px !important;
}
.select-division {
	border: 1px solid #bbb;
	border-radius: 5px !important;
}