.react-loading-skeleton.home__banner__skeleton{
	height: 300px;
    width: 831px;
}
.react-loading-skeleton.home__detail__img__skeleton{
	height: 240px;
	width: 261px;
}
.react-loading-skeleton.home__detail__title__skeleton{
    height: 17px;
    width: 160px;
}
.react-loading-skeleton.home__detail__price__skeleton{
    height: 15px;
    width: 100px;
}


/******** product detail **************/

.productDetail__content {
    margin-left: 20px;
}
.productDetail__title__skeleton {
    margin-bottom: 40px;
}
.productDetail__price__skeleton {
    border-bottom: 1px solid #ddd;
    padding-bottom: 16px;
    margin-bottom: 70px;
}
@media (max-width:600px){
    .react-loading-skeleton.home__banner__skeleton {
        height: 220px;
        width: 100%;
    }
    .react-loading-skeleton.home__detail__img__skeleton {
        height: 156px;
        width: 156px;
    }
    .react-loading-skeleton.home__detail__title__skeleton {
        height: 17px;
        width: 120px;
    }
    .react-loading-skeleton.home__detail__price__skeleton {
        height: 15px;
        width: 80px;
    }


    /******** product detail **************/

    .productDetail__content {
        margin-left: 0px !important;
        text-align: center;
        margin-top: 35px;
    }
    .react-loading-skeleton.productDetail__qunty__skeleton {
        margin-bottom: 20px;
    }
    .productDetail__title__skeleton {
        margin-bottom: 25px !important;
    }
    .productDetail__price__skeleton {
        padding-bottom: 25px;
        margin-bottom: 50px;
    }
}