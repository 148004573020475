@import './breakpoints';
@import './variables';
@import './mixins';

.login-body{
    p{
        color: $gray;
    }
    .create-link-txt{
        color: $gray;
        font-size: $body-size;
        font-family: $primary-font;
        text-decoration: none;
        &:hover{
            color: $black !important;
        }
    }
    .forgot-pass-rel-div{
        position: relative;

        .forgot-txt{
           position: absolute;
           top: 10px;
           right: 12px;
           z-index: 2;
        }
    }
}
.title-h3{
    font-size: $title-size;
    font-family: $secondary-font;
    font-weight: $title-font;
}

// Signup
.buyer-seller-reg-tab{
    .nav-pills{
        display: flex;
        justify-content: space-between;
    }
    li{
        width: 49% !important;
        button{
            width: 100%;
            border-radius: 0;
            color: $black;
            background-color: transparent;
            height: 44px;
           
        }
        .nav-link{
             @include border(1px, solid, #ddd);
             border-radius: 0;
            &.active{
                background-color: #000 !important;
                color: #fff !important;
                border-radius: 0;
                height: 44px;
            }
        }
    }
}


// ============== Responsive ================

@include breakpoints(LGphoneOnly) {

.login-body-mobile-view {
	margin-bottom: 165px;
}
.signup-body-mobile-view {
	margin-bottom: 100px;
}
.reg-success-body-mobile-view {
    margin-top: 125px;
	margin-bottom: 120px;
}
}