// Colors
$black: #000000 !important;
$off-black: #545454 !important;
$light-black:#333333 !important;
$gray: #3F3F3F !important;
$off-gray: #D9D9D9 !important;
$light-gray: #939393 !important;
$dark-white: #E7E7E7 !important;
$off-white: #E7E7E7 !important;
$box-white: #ECEEF2 !important;
$white: #ffffff !important;
$off-dark:#737373 !important;
$Very-dark-gray:#5C5C5C !important;


// Font Family
$primary-font: 'Roboto', sans-serif !important;
$secondary-font: 'Mulish', sans-serif !important;

// Font Size
$normal-font-size:14px !important;
$sm-prod-title:12px !important;
$body-size: 15px !important;
$link-size: 16px !important;
$sidebar-menu-size: 18px !important;
$title-size: 24px !important;
$title-h4: 20px !important;
$product-title-size:22px !important;
$similar-product-title-size: 20px !important;
$product-name-size: 13px !important;
$buyer-profile-title-size: 14px !important;


// Font weight
$title-font: 700 !important;
$light-font: 300 !important;
$normal-font: 400 !important;
$btn-font: 700 !important;
$product-name-font: 600 !important;





