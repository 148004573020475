@import './breakpoints';
@import './variables';
@import './mixins';
@import './home.scss';
@import './login-signup.scss';
@import './product-details.scss';
@import './buyer-profile.scss';
@import './cart-shipping.scss';
@import './product-cart.scss';
@import './message.scss';


body{
    color: $black;
    font-family: $primary-font;
    font-weight: $light-font;
    font-size:$body-size;
}
p{
    font-size:$body-size;
    font-weight: $light-font;
}
h1,h2,h3,h4,h5,h6{
     font-family: $secondary-font;
}
.btn {
    font-family: $secondary-font;
}
input {
    font-family: $primary-font;
}
a{
    font-family: $secondary-font;
}
ul {
    list-style: none;
}
span {
	color: #000;
}
button{
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}
.btn{
    border-radius: 5px !important;
}
select{
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    min-height: 44px !important;
    line-height: 30px !important;
    cursor: pointer;
}
.base-button{
    background-color: $black;
    font-size: $normal-font;
    outline: none !important;
    height: 44px !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    line-height: 30px !important;

}
.form-control{
    border-radius: 5px !important;
    outline: none !important;
    height: 44px !important;
    box-shadow: none !important;
}
// Custom Radio Button
input[type="radio"]
{
  appearance: none;
}

input[type="radio"] + label
{
  cursor: pointer;
  position: relative;
  padding-left: 30px;
}

input[type="radio"] + label::before {
	content: "";
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 1px solid #000;
	border-radius: 50%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 1;
	transition: all 0.3s;
	background: #000;
}
.buyer-other-info {
    input[type="radio"] + label::before {
        top: 7px !important;
        left: 10px !important;
    }
}
.change-buyer-other-info {
    input[type="radio"] + label::before {
        top: 7px !important;
        left: 10px !important;
    }
}


input[type="radio"] + label::after {
	content: "";
	display: inline-block;
	width: 6px;
	height: 6px;
	border: 1px solid #fff;
	background: #fff;
	border-radius: 50%;
	position: absolute;
	left: 7px;
	bottom: 10px;
	opacity: 0;
	transition: all 0.3s;
}
.buyer-other-info {
    input[type="radio"] + label::after {
        top: 14px !important;
        left: 17px;
    }
}
.change-buyer-other-info {
    input[type="radio"] + label::after {
        top: 14px !important;
        left: 17px;
    }
}

input[type="radio"]:checked + label::after
{
  opacity: 1;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
// Custom Margin
.my-150{
    margin-top: 150px !important;
    margin-bottom: 150px !important;
}
.my-200{
    margin-top: 200px !important;
    margin-bottom: 200px !important;
}


// Header Styles
.btn-body {
	position: absolute !important;
	width: 150px !important;
	top: 60px !important;
	background: #fff !important;
}
.header-logo {
	padding-top: 0 !important;
    img {
        height: 71px;
    }
}
.process-loader {
	margin-bottom: -10px;
}
.navbar{
    background-color: $black;
    .navbar-nav{
        flex-direction: row;
        .nav-link{
            color: $light-gray;
            font-size: $link-size;
            font-family: $secondary-font;
            font-weight: $normal-font;
            padding: 15px 0 15px 30px !important;
        }
    }
    .dropdown-menu[data-bs-popper]{
        left: auto !important;
        right: 0 !important;
        border-radius: 0;
        width: 300px;
        background: #ddd;
    }
    .search-box{
        width: 250px !important;
    }
    .menu-card.dropdown-menu[data-bs-popper]{
        left: -12px !important;
        right: auto !important;
        top: 35px;
    }
   
    .menu-toggle-btn{
        background: transparent;
        padding: 0;
        border:0;
    }
    .menu-card {
        position: absolute;
        padding: 10px;
        width: auto !important;
}
}

.custom-cross-btn{
    background-color: #fff;
    border: 1px solid #bbb !important;
    border-radius: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    cursor: pointer;
    padding-right: 50px;
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
    height: 44px;
    border-left: none !important;
}

 @include breakpoints(LGphoneOnly) {
    .dropdown-toggle::after {
        display: none !important;
    }
}

// Footer Styles
footer{
    background-color: $black;
    position: relative;

    .nav-link{
        color: $white;
    }
    .batl-txt{
        color: $light-gray;
        position: absolute;
    }
}
//====================Product Cart page
.cartlist-header {
	@include border-direction(bottom, 1px, solid, $off-white); 
}

// product-categories
.show-more-link{
	color: $gray;
	text-decoration: none;
	font-weight: $title-font;
}
.all-cat-title{
    font-size: $title-h4;
    font-weight: $title-font;
    position: relative;
    padding-bottom: 16px;
    &::after{
        position: absolute;
        content: '';
        left: 10px;
        bottom: 0;
        width: 50px;
        height: 2px;
        background-color: $light-gray;
    }
}

button{
    font-family: $secondary-font;
    font-size: $sidebar-menu-size;
}
.accordion-item {
    border: 0 !important;
}
.accordion-button:not(.collapsed) {
    background-color: #eceef2 !important;
    color: #000;
}
.accordion-button {
    padding: 10px 0 !important;
}

.product-box{
    .prod-box-img{
    //     height: 302px;
    //     background: $box-white;
    //     position: relative;
    //     @include breakpoints(phoneOnly) {
    //         height: 150px !important;
    //     } 
    //     @include breakpoints(phone) {
    //         height: 150px !important;
    //     }
    //     @include breakpoints(LGphone) {
    //         height: 200px !important;
    //     }
    //     img{
    //         position: absolute;
    //         left: 50%;
    //         top: 50%;
    //         transform: translate(-50%, -50%);
    //     }
        text-align: center;
        background: #ECEEF2 !important;
        position: relative;

        img {
            width: auto;
            height: 240px;
            display: unset;
        }
    }
   
    .prod-box-content{
        .product-title{
            font-size: $product-name-size;
            font-weight: $product-name-font;
        }  
        .product-price{
            font-size: $product-name-size;
            font-weight: $normal-font;
        }  
    }
}
.home-product-stock {
	position: absolute;
	top: 0px;
	right: 1px;
	background: #000;
	color: #fff;
	padding: 3px 8px;
}
.slider-product-stock {
	position: absolute;
	top: 0px;
	right: 0px;
	background: #000;
	color: #fff;
	padding: 3px 8px;
}
// Breadcrumbs
.breadcrumbs{
    ul{
        li{
            position: relative;
            &::after{
                position: absolute;
                content: '';
                top: 7px;
                right: -16px;
                background-image: url(../images/right-arrow.png);
                width: 6px;
                height: 11px;
                background-repeat: no-repeat;
            }
             &:last-child{
                &::after{
                   display: none;
                }
            }

            a{
                color: $off-dark;
                text-decoration: none;
            }  
        }
        .active a{ 
            color: #000 !important;
        }
    }
}


.stepper {
	height: 250px;
	overflow: auto;

  .line {
    width: 2px;
    background-color: lightgrey !important;
  }
  .active-line {
        background-color: #000 !important;
    }
  .lead {
    font-size: 1.1rem;
  }
  .rounded-circle.stepper-round {
        width: 15px;
        height: 15px;
        margin-top: 2px;
    }

.rounded-box {
	margin-right: 10px;
    position: relative;
}
.active-rounded {
	width: 23px;
	height: 23px;
	border: 1px solid #000;
	border-radius: 50%;
	position: absolute;
	margin-top: -2px;
}

}

.rating-box{
    span{
        color: #E66E56;
    }
    
}
.light-gray-box{
    background-color: $dark-white;
    margin-bottom: 10px;
    img {
        height: 100px;
    }
}
.chat-shop-ownner-btn {
	color: #000;
	text-decoration: none;
	border: 1px solid #E7E7E7;
	display: block;
	text-align: center;
	padding: 10px;
	border-radius: 4px;
	margin: 20px 0;
    img{
        margin-right: 5px;
    }
}
.time-counter {
    .counter-rounded{
        width: 50px;
        border: 1px solid #e7e7e7;
        height: 50px;
        text-align: center;
        line-height: 48px;
        border-radius: 50%;
        margin: auto;
            margin-bottom: auto;
        font-size: 13px;
        margin-bottom: 5px;
    }
     p {
	    font-size: 12px !important;
    }
}


// ************ payment Success *****************
.Successful-payment-main {
	background: #808080c7;
	color: #fff;
	margin: 100px 0;
	padding: 100px 0;
	border: 1px solid #808080c7;
	border-radius: 10px;
}
.success-payment-text {
    p {
        font-size: 30px !important;
    }
}
.success-payment-icon {
    span {
        color: #fff !important;
        font-size: 30px;
        background: #01a601;
        padding: 10px 14px;
        border: 1px solid #01a601;
        border-radius: 50%;
    }
}
.footer-menu-title {
	font-size: 30px;
	font-weight: 600;
	margin: 20px 0 !important;
}
.police-page-wrap {
	border: 5px solid;
	margin-bottom: 30px;
    padding: 10px 0;
    .policy-item {
        margin-bottom: 10px;
        .accordion-button {
            background: #ddd;
            padding: 14px !important;
        }
        .accordion-body {
            text-align: justify;
        }
    }
}

p.payment-success-order-id {
  font-size: 20px !important;
  margin-bottom: 30px;
}
.back-home-btn-wrap {
	font-size: 20px;
	margin-top: 20px;
    .back-home-btn {
        color: #fff !important;
        border-bottom: 1px solid #fff;
        padding-bottom: 3px
    }
}
// ================ Responsive =====================

@include breakpoints(LGphoneOnly) {
    footer {
        .batl-txt {
            bottom: 0px;
            right: 42%;
        }
    } 
    .product-box .prod-box-img img {
        height: 165px;
    }
    .footer-menu-content {
        height: 200px;
    }
}


.country-search-box {
    select {
        background-color: white;
        border: thin solid black;
        display: inline-block;
        font: inherit;
        line-height: 1.5em;
        padding: 0.5em 3.5em 0.5em 1em;
        margin: 0;      
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
      }
      
      select.classic {
        background-image:
          linear-gradient(45deg, transparent 50%, #fff 50%),
          linear-gradient(135deg, #fff 50%, transparent 50%),
          linear-gradient(to right, black, black);
        background-position:
          calc(100% - 20px) calc(1em + 2px),
          calc(100% - 15px) calc(1em + 2px),
          100% 0;
        background-size:
          5px 5px,
          5px 5px,
          2.5em 2.5em;
        background-repeat: no-repeat;
      }
      
      select.classic:focus {
        background-image:
          linear-gradient(45deg, white 50%, transparent 50%),
          linear-gradient(135deg, transparent 50%, white 50%),
          linear-gradient(to right, black, black);
        background-position:
          calc(100% - 15px) 1em,
          calc(100% - 20px) 1em,
          100% 0;
        background-size:
          5px 5px,
          5px 5px,
          2.5em 2.5em;
        background-repeat: no-repeat;
        border-color: #fff;
        outline: 0;
      }
}
.side-menu-overlay {
	position: fixed;
	z-index: 998;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4); 
	transition: opacity 0.3s, visibility 0.3s;
	-webkit-transition: opacity 0.3s, visibility 0.3s;
	-ms-webkit-transition: opacity 0.3s, visibility 0.3s;
}
.back-home-main {
	margin-top: 15px;
	margin-right: 20px;
	border-bottom: 1px solid;
	padding-bottom: 6px;
	padding-left: 10px;
}
.back-home-icon {
	float: right;
	margin-right: 15px;
	font-size: 20px;
	margin-top: -3px;
}
.footer-social-main {
	margin: 0 20px;
    .footer-social-icon {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
    }
}
