@import './breakpoints';
@import './variables';
@import './mixins';


.product-details {
    .item-color {
        color: #717171;
    }
}
.cart-qty {
    border: 1px solid #e7e7e7;
	width: 120px;
	display: flex;
	justify-content: space-between;
	padding: 0 5px;
    button {
        background: none;
        border: none;
        font-size: 15px !important;
    }
   
}
.down__arrow button::after{
    height: 0 !important;
}
.sub-menu-name {
	padding: 10px 25px !important;
     p {
        margin-bottom: 0 !important;
        cursor: pointer;
        font-family: $secondary-font;
    }
}
.cartlist-body {
	padding: 15px 0;
	border-bottom: 1px solid #e7e7e7;

    .product-img {

        img {
            height: 120px !important;
            width: auto;
        }
    }
}
.form-select-arrow {
	background-image: none !important;
}
.est-shpping-box{
    margin: 60px 0;
    padding: 30px;
    border: 1px solid #E7E7E7;
    h3 {
        text-align: center;
        background: #fff;
        width: max-content;
        margin: -48px auto 30px;
        padding: 0 5px;
    }
}
.user-type.mt-3 p {
	font-size: 20px !important;
}

.dfrnt-shipping-amount {
    font-size: 18px !important;
}
.shipping-charge-row-gap {
    margin-top: -32px !important;
}
.or--1, .or--2 {
  position: relative;
}
.or {
  display: block;
  margin: 2rem 0;
}
.or--1:before {
  left: 0;
}
.or--1:after, .or--1:before {
  content: '';
  position: absolute;
  width: calc(50% - 30px);
  height: 30px;
  border-bottom: 1px dashed #000;
}
.or--1 > span {
  display: inline-block;
  height: 30px;
  position: relative;
  transform: translate(-50%, 50%);
  font-size: 0.875rem;
  color: #000;
}
.or--1:after {
  right: 0;
}
.or--1:after, .or--1:before {
  content: '';
  position: absolute;
  width: calc(50% - 30px);
  height: 30px;
  border-bottom: 1px dashed #000;
}
 @include breakpoints(LGphoneOnly) {
    .cartlist-header{
        display: none;
    }
    .cart-remove-link {
        margin-top: 10px;
        display: block;
    }
    .est-shpping-box h3 {
        margin: -38px auto 30px;
        padding: 0 5px;
        font-size: 13px;
    }
    .est-shpping-box .form-control, .est-shpping-box .form-select{
        margin-bottom: 10px;
    }
    .user-type {
        text-align: center;
    }
    .payment-check-mobile-view {
        display: initial;
    }
    .pay-now-btn-mobile-view {
        width: 100%;
    }
    .shipping-charge-row-gap {
        margin-top: -10px !important;
    }
}
.expected-date-time {
    font-weight: 400 !important;
}
#cartDisable{

}

